exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-alien-map-js": () => import("./../../../src/pages/alien-map.js" /* webpackChunkName: "component---src-pages-alien-map-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-jsx": () => import("./../../../src/pages/projects.jsx" /* webpackChunkName: "component---src-pages-projects-jsx" */),
  "component---src-pages-test-store-jsx": () => import("./../../../src/pages/testStore.jsx" /* webpackChunkName: "component---src-pages-test-store-jsx" */),
  "component---src-pages-trampoline-jsx": () => import("./../../../src/pages/trampoline.jsx" /* webpackChunkName: "component---src-pages-trampoline-jsx" */),
  "component---src-pages-trip-tips-jsx": () => import("./../../../src/pages/trip-tips.jsx" /* webpackChunkName: "component---src-pages-trip-tips-jsx" */)
}

